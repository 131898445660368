.article-wp {
  > *:first-child {
    margin-top: 0 !important;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  h2 {
    font-size: 27px;
    font-weight: 600;
    color: $bl-a;
  }

  h3 {
    font-size: 23px;
    font-weight: 600;
    color: $bl-a;
  }

  .headline {
    font-size: 17px;
    font-weight: 600;
  }

  p {
    font-size: 15px;
    margin-bottom: px(15);
  }

  .highlight-wp {
    margin-bottom: 0;
    color: $bl-a;
    font-size: 16px;
    font-weight: 600;
    font-style: italic;
    border: 2px solid $bl-a;
    padding: 30px;
    margin: 50px auto;
    width: 75%;
    display: block;
  }

  ol,
  ul {
    padding-left: 30px;
  }

  ol li,
  ul li {
    display: block;
    position: relative;
    padding-left: 22px;
    margin-bottom: 10px;
  }

  ul li {
    font-weight: 400;
    font-size: 15px;
  }

  ul li:before {
    content: "";
    @include position(absolute, 12px null null 3px);
    width: 8px;
    height: 2px;
    background-color: $bl-a;
  }

  ol {
    counter-reset: ol-counter;
  }

  ol li {
    position: relative;
    display: block;
    line-height: 1.4em;
    font-size: 15px;
    font-weight: 400;
  }

  ol li:before {
    content: counter(ol-counter)".";
    color: $bl-a;
    counter-increment: ol-counter;
    font-size: px(14);
    font-weight: 600;
    position: absolute;
    top: 0;
    left: 0;
  }

  ol li:last-child {
    margin-bottom: 0;
  }

  h2,
  h3,
  ol,
  ul {
    margin: 30px 0 35px;
  }

  a {
    color: $bl-a;
    text-decoration: underline;
  }

  .btn-wp {
    margin: 30px 0;
  }

  .btn-wp a {
    @extend .btn-a;
    text-decoration: none;
    padding-right: px(20);
  }

  img {
    max-width: 100%;
    height: auto;
  }

  img.img-left {
    float: left;
    margin: 5px 30px 15px 0;
  }

  img.img-right {
    float: right;
    margin: 5px 0 15px 30px;
  }

  img.img-center {
    margin: 50px auto;
    display: block;
  }

  table {
    @extend .table;
    @extend .table-striped;
    @extend .table-bordered;
  }
  @include media-breakpoint-down(sm) {
    h2 {
      font-size: 25px;
    }

    h3 {
      font-size: 21px;
    }

    .headline {
      font-size: 16px;
    }

    .highlight-wp {
      width: 100%;
    }

    img.img-left,
    img.img-right {
      float: none;
      margin: 30px 0 !important;
      display: block;
    }

    .btn-wp a {
      padding-right: px(15);
    }
  }
  @include media-breakpoint-down(xs) {
    h2 {
      font-size: 22px;
    }

    h3 {
      font-size: 19px;
    }

    .headline {
      font-size: 15px;
    }

    li,
    ol li:before,
    p {
      font-size: 14px !important;
    }

    .highlight-wp {
      width: 100%;
    }

    ol,
    ul {
      padding-left: 0;
    }

    img,
    img.img-center {
      float: none;
      margin: 20px 0 !important;
      display: block;
    }

    h2,
    h3,
    ol,
    ul {
      margin: 20px 0;
    }

    .highlight-wp {
      margin: 20px 0;
      padding: 20px;
    }
  }
}
@include media-breakpoint-down(xs) {
  body .article-wp > *:first-child {
    margin-top: 0 !important;
  }

  body .article-wp > *:last-child {
    margin-bottom: 0 !important;
  }
}

#modal-content {
  .article-wp h2 {
    font-size: 20px;
  }

  .article-wp h3 {
    font-size: 17px;
  }

  .article-wp h2,
  .article-wp h3,
  .article-wp ol,
  .article-wp ul {
    margin: 20px 0;
  }

  .article-wp p {
    font-size: 14px;
  }
  @include media-breakpoint-down(sm) {
    .article-wp h2 {
      font-size: 18px;
    }

    .article-wp h3 {
      font-size: 15px;
    }
  }
}

.home-content{
  img.img-home {
    width:80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
    object-fit: contain;
    @include media-breakpoint-down(sm) {
      position: relative;
    }
  }
  .article-wp{
    min-height: 300px;
    @include media-breakpoint-down(sm) {
      min-height: unset;
    }
  }
}