// Footer

#pre-footer {
	position: relative;
	background-color: $or-a;
	padding: 50px 0;

	.img-bg {
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		@media (max-width: 1250px) {
			display: none;
		}
	}
	.list {
		display: flex;
		justify-content: center;
		.item {
			padding: 0 40px;
			text-align: center;
			.txt {
				margin-top: 15px;
				font-size: 14px;
				line-height: 1.3em;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 30px 0;

		.list {
			display: block;
			text-align: center;
			.item {
				width: 48%;
				padding: 0;
				vertical-align: top;
				display: inline-block;
			}
		}
	}
	@include media-breakpoint-down(xs) {
		.item {
			width: 100%;
			padding: 0 25px;
		}
	}
}
#footer {
	background-color: $wh-a;
	color: $wh-a;
	font-size: 13px;
	@include media-breakpoint-down(xs) {
		text-align: center;
	}

	@include media-breakpoint-down(md) {
		font-size: 12px;
	}

	font-weight: 300;
	.logo {
		.txt {
			margin-top: 10px;
			font-size: 15px;
			font-weight: 500;
			strong {
				font-weight: 700;
			}
			@include media-breakpoint-down(lg) {
				font-size: 14px;
			}
			@include media-breakpoint-down(md) {
				font-size: 10px;
				margin-bottom: 5px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 15px;
				margin-bottom: 5px;
			}
		}
	}
	.follow {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.phone {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 15px;
		font-weight: 700;
		font-size: 22px;
		.txt {
			padding-left: 10px;
		}
	}
	.list {
		display: flex;
		justify-content: center;
		> * {
			position: relative;
			padding: 0 20px;
			&:not(:last-child) {
				&:before {
					content: '';
					height: 12.5px;
					width: 1px;
					background-color: $wh-a;
					position: absolute;
					top: 50%;
					margin-top: -7px;
					right: 0;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		.list {
			> * {
				padding: 0 10px;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.follow,
		.phone,
		.list {
			justify-content: flex-start;
		}
		.list {
			margin-left: -10px;
		}
	}
	@include media-breakpoint-down(xs) {
		.follow,
		.phone {
			display: inline-block;
			> * {
				display: inline-block;
				vertical-align: middle;
			}
		}
		.list {
			display: inline-block;
			text-align: left;
			> * {
				margin-top: 10px;
				&:before {
					display: none;
				}
			}
		}
	}
}

#footer-bottom {
	color: $bk-a;
	.txt {
		font-size: 11px;
	}



	.payment {
		display: flex;
		justify-content:center;
		align-items: center;
		@include media-breakpoint-down(sm) {
			justify-content: flex-start;
		}



		.txt {
			padding-right: 10px;
		}
	}

	@include media-breakpoint-down(xs) {
		padding-top: 60px;
		.payment {
			display: block !important;
			position: absolute;
			top: -80px;
			left: 50%;
			margin-left: -70px;
			.sprite {
				display: block;
			}
		}
	}

}
