// Sprite :: Structure

.sprite {
	display: inline-block;
	background-image: url("../images/icons/sprite.png");
	background-repeat: no-repeat;
	vertical-align: text-top;
	background-size: 1100px;

	&.back-top {
		width: 50px;
		height: 50px;
	}

	&.back-top {
		background-position: 0 0;
	}

	&.instagram-white,
	&.facebook-blue,
	&.facebook-white,
	&.phone-white {
		width: 30px;
		height: 30px;
	}

	&.phone-white {
		background-position: -150px 0;
	}

	&.instagram-white {
		background-position: -0px -50px;
	}

	&.facebook-blue {
		background-position: -50px 0;
	}

	&.facebook-white {
		background-position: -80px 0;
	}

	&.footer-secure {
		width: 150px;
		height: 40px;
		background-position: -50px -30px;
	}

	&.experience-icon-1,
	&.experience-icon-2,
	&.experience-icon-3,
	&.experience-icon-4 {
		width: 90px;
		height: 90px;
	}

	&.experience-icon-1 {
		background-position: -200px 0;
	}

	&.experience-icon-2 {
		background-position: -290px 0;
	}

	&.experience-icon-3 {
		background-position: -380px 0;
	}

	&.experience-icon-4 {
		background-position: -470px 0;
	}

	&.arrow-blue-xs,
	&.user-white-xs {
		width: 20px;
		height: 20px;
	}

	&.user-white-xs {
		background-position: -130px 0;
	}

	&.arrow-blue-xs {
		background-position: -110px 0;
	}

	&.vu,
	&.vp {
		width: 40px;
		height: 19px;
	}
	&.vu {
		background-position: -660px 0;
	}
	&.vp {
		background-position: -580px 0;
	}
}

.sprite.vehicle-boite-a,
.sprite.vehicle-boite-m,
.sprite.vehicle-clim,
.sprite.vehicle-motorisation-d,
.sprite.vehicle-motorisation-e,
.sprite.vehicle-passager-2,
.sprite.vehicle-passager-3,
.sprite.vehicle-passager-4,
.sprite.vehicle-passager-5,
.sprite.vehicle-passager-6,
.sprite.vehicle-passager-7,
.sprite.vehicle-passager-8,
.sprite.vehicle-passager-9,
.sprite.vehicle-porte-2,
.sprite.vehicle-porte-3,
.sprite.vehicle-porte-4,
.sprite.vehicle-porte-5,
.sprite.vehicle-bagage,
.sprite.vehicle-radio {
	width: 20px;
	height: 20px;
}

.sprite.vehicle-clim {
	background-position: 0 -100px;
}

.sprite.vehicle-radio {
	background-position: -20px -100px;
}

.sprite.vehicle-passager-2 {
	background-position: -40px -100px;
}

.sprite.vehicle-passager-3 {
	background-position: -60px -100px;
}

.sprite.vehicle-passager-4 {
	background-position: -80px -100px;
}

.sprite.vehicle-passager-5 {
	background-position: -100px -100px;
}

.sprite.vehicle-passager-6 {
	background-position: -120px -100px;
}

.sprite.vehicle-passager-7 {
	background-position: -140px -100px;
}

.sprite.vehicle-passager-8 {
	background-position: -160px -100px;
}

.sprite.vehicle-passager-9 {
	background-position: -180px -100px;
}

.sprite.vehicle-porte-2 {
	background-position: -200px -100px;
}

.sprite.vehicle-porte-3 {
	background-position: -220px -100px;
}

.sprite.vehicle-porte-4 {
	background-position: -240px -100px;
}

.sprite.vehicle-porte-5 {
	background-position: -260px -100px;
}

.sprite.vehicle-boite-m {
	background-position: -280px -100px;
}

.sprite.vehicle-boite-a {
	background-position: -300px -100px;
}

.sprite.vehicle-motorisation-d {
	background-position: -320px -100px;
}

.sprite.vehicle-motorisation-e {
	background-position: -340px -100px;
}

.sprite.vehicle-bagage {
	background-position: -360px -100px;
}
