#resa-home {
  background-image: url("../images/illustrations/bg-resa.jpg");
  background-position: center bottom;
  background-size: cover;
  min-height: 420px;
  padding-top: 120px;
  position: relative;

  &.except-resa {
    background: -moz-linear-gradient(top, #f68a00 0%, #ed5504 100%);
    background: -webkit-linear-gradient(top, #f68a00 0%,#ed5504 100%);
    background: linear-gradient(to bottom, #f68a00 0%,#ed5504 100%);
    min-height: 0;
    padding: 0 !important;

    .form {
      position: relative;
      padding: 20px 30px !important;
      margin: 0 !important;
      bottom: auto !important;
      background: none !important;
    }
  }

  &.except-page {
    min-height: auto !important;
    padding: 40px 0 40px 0 !important;
    @include media-breakpoint-down(md) {
      padding: 25px 15px !important;
    }

    &.except-utilitaire {
      padding-top: 70px !important;
    }

    .form {
      bottom: auto !important;
      margin: 0 !important;
      position: relative !important;
    }
  }
  @include media-breakpoint-down(lg) {
    min-height: 280px;
    padding-top: 70px;

    .form {
      bottom: -30px !important;
    }
    &.except-utilitaire:not(.except-page) {
      .form {
        bottom: -40px !important;
      }
    }
  }
  @include media-breakpoint-down(xs) {
    min-height: 100%;
    padding: 30px;

    &.except-utilitaire {
      padding-top: 100px !important;
    }

    .form {
      margin-top: 20px;
      bottom: auto !important;
    }
  }

  .align-picker-1 {
    display: flex;
    align-items: center;

    .label {
      margin-bottom: 0;
    }

    .selectpicker-wp {
      width: 125px;
      margin-left: 15px;
    }
  }

  .align-picker-2 {
    display: flex;
    align-items: center;

    .label {
      margin-bottom: 0;
    }

    .form-control {
      width: 150px;
      margin-left: 15px;
    }
  }
  @include media-breakpoint-down(md) {
    .align-picker-1,
    .align-picker-2 {
      display: block;

      .label {
        margin-bottom: 5px;
      }

      .form-control,
      .selectpicker-wp {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  .label {
    color: $wh-a;

    &.strong {
      font-family: 'museo500';
    }
  }

  .title {
    color: $wh-a;
    font-family: 'museo500';
    text-align: center;
    text-shadow: 0 5px 6px rgba(0,0,0,0.7);
    font-size: 34px;
    @include media-breakpoint-down(md) {
      font-size: 28px;


      .except-utilitaire & {
        min-height: 300px;
      }
    }
    @include media-breakpoint-down(sm) {
      font-size: 20px;
    }
  }

  .double-picker {
    display: flex;
    border: 1px solid #CFD4D9;
    background-color: $wh-a;

    > div:first-child {
      width: 65%;
      border-right: 1px solid #CFD4D9;

      .custom-file-wp .datepicker-wp .custom-file-label,
      .datepicker-wp .custom-file-wp .custom-file-label,
      .datepicker-wp .form-control,
      .datepicker-wp .selectpicker-wp .dropdown-toggle,
      .selectpicker-wp .datepicker-wp .dropdown-toggle {
        padding-right: 10px !important;
        border: none;
      }

      .ui-datepicker-trigger {
        display: none;
      }

      .form-control {
        height: calc(2.25rem + 0px);
      }
    }

    > div:last-child {
      width: 35%;

      .selectpicker-wp .dropdown-toggle:after {
        display: none;
      }

      .selectpicker-wp .dropdown-toggle {
        padding-right: 1rem !important;
        border: none;
        height: calc(2.25rem + 0px);
      }
    }
  }

  .form {
    position: relative;
    z-index: 10;
    bottom: -40px;
    padding: 15px 40px 20px;
    background: #f68a00;
    background: -moz-linear-gradient(top, #f68a00 0%, #ed5504 100%);
    background: -webkit-linear-gradient(top, #f68a00 0%,#ed5504 100%);
    background: linear-gradient(to bottom, #f68a00 0%,#ed5504 100%);
    @include media-breakpoint-down(sm) {
      padding: 15px 30px 20px;
    }
    @include media-breakpoint-up(xl) {
      position: absolute;
      max-width: calc(1140px - (2 * 6.66rem));

    }


    .onglets {
      position: absolute;
      top: -2.5rem;
      left: 50px;

      .onglet {
        display: inline-block;
        padding: 0.5rem 1rem;
        margin-right: 10px;
        background-color: #777;
        color: white;
        cursor: pointer;
      }

      [type=radio]:checked + .onglet {
        background-color: #f68a00;
      }

      input {
        position: absolute;
        left: -99999em;
        top: -99999em;
        opacity: 1;
        z-index: 1;
      }

      @include media-breakpoint-down(sm) {
        left: 10px;
      }

      @media (max-width: 380px) {
        .sprite {
          display: none;
        }
      }
    }
  }
}

.site-budget-guadeloupe #resa-home {
  background-image: url("../images/illustrations/bg-resa-guadeloupe.jpg");
}