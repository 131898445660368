body {
	overflow: hidden;
	overflow-y: auto;
}

body,
html {
	width: 100%;
	background-color: #fff;
}

html {
	overflow-x: hidden;
}
// Utils :: Image renderer optimize

.renderer-optmimize {
	image-rendering: -moz-crisp-edges;
	image-rendering: -moz-crisp-edges;
	image-rendering: -o-crisp-edges;
	image-rendering: -webkit-optimize-contrast;
	-ms-interpolation-mode: nearest-neighbor;
}
// Utils :: Hide play button on video iphone

*::-webkit-media-controls-start-playback-button {
	display: none !important;
	-webkit-appearance: none;
}
// Components :: Magnific popup

.mfp-close {
	width: 30px !important;
	height: 30px;
	line-height: 30px;
	position: absolute;
	right: 0;
	top: 5px;
	text-decoration: none;
	text-align: center;
	opacity: 0.65;
	filter: alpha(opacity=65);
	padding: 0 0 18px 10px;
	color: #FFF;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
	display: block;
	background-image: url("../images/icons/sprite-popup.png") !important;
	background-position: -60px 0 !important;
	text-indent: 999em;
	overflow: hidden!important;
	transition: all 0.2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow {
	position: absolute;
	opacity: 0.65;
	filter: alpha(opacity=65);
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 30px;
	height: 60px;
	display: block;
	background-image: url("../images/icons/sprite-popup.png") !important;
	transition: all 0.2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow-left {
	left: 20px;
	background-position: 0 0 !important;
}

.mfp-arrow-right {
	right: 20px;
	background-position: -30px 0 !important;
}

.mfp-arrow:after,
.mfp-arrow:before {
	display: none !important;
}

.mfp-zoom-out .mfp-with-anim {
	opacity: 0;
	transform: scale(1.3);
	transition: all 0.2s ease-in-out;
}

.mfp-zoom-out.mfp-bg {
	opacity: 0;
	transition: all 0.2s ease-in-out;
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
	opacity: 1;
	transform: scale(1);
}

.mfp-zoom-out.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
	transform: scale(1.3);
	opacity: 0;
}

.mfp-zoom-out.mfp-removing.mfp-bg {
	opacity: 0;
}
// Components :: Slick

.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	margin-top: px(15);
	text-align: center;

	li {
		display: inline-block;
		vertical-align: top;
		margin: 0 2px;
	}

	button {
		width: 14px;
		height: 14px;
		margin-right: 5px;
		background-color: $bk-a;
		border-radius: px(18);
		transition: all 0.2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
	}

	.slick-active button {
		background-color: $bk-a;
	}

	button:focus,
	button:hover {
		background-color: $bk-a;
	}
}
// Components :: WOW

.fadeIn,
.fadeInDown,
.fadeInLeft,
.fadeInRight,
.fadeInUp {
	visibility: hidden;
}
// Function :: Equal Col
@include media-breakpoint-down(md) {
	.equal-col-item {
		min-height: 0 !important;
	}
}
// Component :: Google map

.map-wrapper {
	position: relative;
	height: 300px;
	background-color: #e5e3df;

	.map-canvas {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}
// Component :: Parallax

.parallax-mirror {
	z-index: 0 !important;
}

.parallax-wp {
	@extend .clearfix;
	overflow: hidden;
	position: relative;
	height: 300px;
	background: transparent;
}
@include media-breakpoint-down(sm) {}
@include media-breakpoint-down(xs) {}
// Slick :: Home

#slick-wp {}

#slick {}

#slick-wp {
	.item {}

	#slick-arrows {
		.arrow-next,
		.arrow-prev {}

		.arrow-prev {}

		.arrow-next {}
	}

	.slick-dots {}
	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {}
	@include media-breakpoint-down(sm) {}
	// Animation classes
	// .slick-slide .content							{ opacity: 0; transform: translateY(-30px) scale(1); transition: all 0.2s ease-in-out 0.5s;}
	// .slick-slide.animation .content					{ opacity: 1; transform: translateY(0) scale(1); }
}

.article-blog {
	height: 100%;

	.link {
		height: 100%;
		display: flex;
		flex-flow: column wrap;
		justify-content: space-between;

		> * {
			transition: color ease-in-out 0.2s;
		}

		p {
			font-size: 13px;
			margin-bottom: 0;
		}

		.btn {
			color: $or-a;
			font-weight: 600;
			font-size: 13px;
		}

		&:hover {
			color: $or-a;
		}
	}
}

.item-faq {
	display: block;
	width: 100%;
	position: relative;
	text-align: left;
	line-height: 1.2em;
	box-shadow: 0 0 20px rgba(0,0,0,0.1);
	border-radius: 8px;
	transition: all 0.2s ease-in-out;
	background-color: $wh-a;

	.button {
		color: $or-a;
		width: 100%;
		line-height: 1.3em;
		text-align: left;
		font-size: px(18);
		display: block;
		font-family: 'museo500';
		padding: 18px 60px 20px 25px;
		position: relative;
	}

	.button .material-icons {
		position: absolute;
		top: 17px;
		right: 15px;
		color: $or-a;
	}

	.content {
		padding: 0 25px 20px;
		margin-top: -8px;
		transition: all 0.2s ease-in-out;
	}

	.content a {
		text-decoration: underline;
		color: $or-a;
	}

	.content p {
		font-size: px(15);
		line-height: 1.4em;
		margin-bottom: 0;
	}

	&.open {
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
	}

	&.open .button .material-icons {
		transform: rotate(-180deg);
	}

	&.open .content {
		border-color: $wh-a;
	}
	@include media-breakpoint-down(lg) {
		.button {
			font-size: px(18);
		}
	}
	@include media-breakpoint-down(sm) {
		.button {
			font-size: px(16);
			padding: 15px 60px 17px 25px;
		}

		.content p {
			font-size: px(12);
		}

		.button .material-icons {
			top: 14px;
			font-size: px(20);
		}
	}
	@include media-breakpoint-down(xs) {
		.button {
			font-size: px(15);
			padding: 15px 50px 17px 20px;
		}

		.content p {
			font-size: px(11.5);
		}

		.content {
			padding: 0 20px 20px;
		}
	}
}

#header-page {
	background-color: $bl-a;
	color: $wh-a;
	text-align: center;
	padding: px(35) 0;
	box-shadow: 0 0 5px -1px inset rgba(0, 0, 0, 0.5);

	.title {
		font-size: px(36);
		font-weight: 400;
	}
	@include media-breakpoint-down(lg) {
		.title {
			font-size: px(32);
		}
	}
	@include media-breakpoint-down(md) {
		padding: px(30) 0;

		.title {
			font-size: px(29);
		}
	}
	@include media-breakpoint-down(sm) {
		padding: px(25) 0;

		.title {
			font-size: px(26);
		}
	}
	@include media-breakpoint-down(xs) {
		padding: px(15) 0;

		.title {
			font-size: px(22);
		}
	}
}

.selectpicker-wp.select-category {
	width: 220px;
	display: inline-block;

	.bootstrap-select {
		width: 220px !important;
	}

	.bootstrap-select.show .dropdown-toggle:after {
		transform: rotate(180deg);
	}

	.dropdown-toggle {
		display: block;
		padding: 8px 15px;
		border-radius: 5px;
		outline: none !important;
		border: 1px solid $or-a;
	}

	.dropdown-toggle:after {
		color: $wh-a;
	}

	.dropdown-toggle {
		height: px(35);
		background-color: $or-a !important;
	}

	.filter-option-inner-inner {
		font-size: px(13) !important;
	}

	.filter-option-inner-inner {
		color: $wh-a !important;
		font-size: px(14);
	}

	.dropdown-menu li a:active {
		background-color: #f8f9fa !important;
		color: $bk-a !important;
	}
	@include media-breakpoint-down(md) {
		width: 100%;
	}
	@include media-breakpoint-down(sm) {
		.bootstrap-select {
			width: 100% !important;
		}
	}
}

.map-wrapper {
	position: relative;
	height: 300px;
	background-color: #e5e3df;

	.map-canvas {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	@include media-breakpoint-down(md) {
		height: 250px;
	}
	@include media-breakpoint-down(sm) {
		height: 200px;
	}
}

.item-contact {
	.title-contact {
		position: relative;
		color: $or-a;
		padding-left: 23px;
		font-family: 'museo500';
		font-size: px(14.5);
	}

	.title-contact .material-icons {
		position: absolute;
		top: 1px;
		left: 0;
		font-size: px(18);
	}

	.list {
		margin-top: 8px;
		padding-left: 25px;
	}

	.list li {
		display: block;
		position: relative;
		padding-left: 11px;
		font-size: px(13.5);
		line-height: 1.4em;
		word-break: break-word;
	}

	.list li:not(:last-child) {
		margin-bottom: 7px;
	}

	.list li:before {
		content: "";
		position: absolute;
		top: 9px;
		left: 0;
		width: 4px;
		height: 1px;
		background-color: $gy-a;
	}
	@include media-breakpoint-down(sm) {
		display: inline-block;
		width: 49.5%;
		vertical-align: top;
	}
	@include media-breakpoint-down(xs) {
		float: block;
		width: 100%;
	}
}

.section-form-wp {
	padding: 25px;
	background-color: $wh-a;
	box-shadow: 0 0 20px rgba(0,0,0,0.1);
	border-radius: 8px;

	.label-bis {
		font-size: px(12);
		color: $or-a;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 0;
	}

	.form-control-bis {
		display: block;
		width: 100%;
		border: none;
		border-bottom: 1px solid shade($gy-a, 10%);
		color: shade($gy-a, 50%);
		font-size: px(14);
		padding: 6px 0;
		outline: none !important;
		-webkit-appearance: none;
		border-radius: 0 !important;
	}

	.password {
		display: block;
		margin-top: px(8);
		font-size: px(12);
		font-style: italic;
		text-decoration: underline;
	}

	textarea.form-control-bis {
		margin-top: 5px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.dropdown.bootstrap-select {
		display: block;
		width: 100% !important;
		padding: 0;
		border-bottom: 1px solid shade($gy-a, 10%);

		.dropdown-toggle {
			border: none;
			padding: 0;
		}

		.filter-option-inner {
			font-style: italic;
			color: shade($gy-a, 40%) !important;
			font-size: px(13);
		}
	}

	.form-control-bis.active,
	.form-control-bis:focus {
		border-bottom-color: shade($gy-a, 50%);
	}

	.form-control-bis::placeholder {
		color: shade($gy-a, 40%) !important;
		font-style: italic;
		opacity: 1;
		font-size: px(13);
	}
	@include media-breakpoint-down(sm) {
		padding: 20px 20px 25px !important;
	}
}

.item-services {
	height: 100%;
	display: flex;
	flex-flow: column wrap;
	justify-content: space-between;
	background-color: $wh-a;
	padding: px(20);
	box-shadow: 0 0 20px rgba(0,0,0,0.1);
	border-radius: 10px;

	img {
		border-radius: 3px;
	}

	.title {
		font-size: px(18);
		color: $bl-a;
		font-weight: 500;
		padding-top: px(15);
		font-family: 'museo500';
		line-height: 1.2em;
	}

	.desc {
		line-height: 1.3em;
		font-size: px(14);
		margin-top: px(8);
		color: tint($bk-a, 40%);
	}

	.btn-a {
		padding: px(10) px(17);
		font-size: px(12);
	}
	@include media-breakpoint-down(lg) {
		.title {
			font-size: px(19);
		}
	}
	@include media-breakpoint-down(md) {
		.title {
			font-size: px(18);
		}
	}
	@include media-breakpoint-down(xs) {
		.title {
			font-size: px(17);
		}
	}
}

.btn-cgv {
	width: 100%;
	color: #666666;
	text-align: center;
	display: block;
	padding: 0.71429rem 0;
	font-size: 0.85714rem;
	text-decoration: underline;
}

.category-label {
	display: inline-block;
	color: $wh-a;
	background-color: transparent;
	font-weight: 500;
	border: 1px solid $wh-a;
	letter-spacing: 0.05em;
	font-size: px(11);
	padding: 4px 10px;
	border-radius: 3px;
	@include media-breakpoint-down(sm) {
		margin-bottom: px(10);
	}
}

.separator {
	position: relative;
	&:before {
		content:"";
		display: block;
		width: 200px;
		height: 1px;
		background: shade($gy-a, 10%);
		margin: 35px auto;
		@include media-breakpoint-down(sm) {
			margin: 20px auto;
		}
	}
}

.btn-payment {
  position: relative;
  background-color: $or-b;
  color: $wh-a;
  border-radius: 10px;
  overflow: hidden;
  text-transform: uppercase;
  padding: px(10) px(20) px(10) px(75);

  .icon-left {
    position: absolute;
    height: 100%;
    width: 50px;
    padding-top: px(12);
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.1);
  }

  .icon-right,
  .txt {
    display: inline-block;
    vertical-align: middle;
  }

  .icon-right {
    position: relative;
    top: 3px;
  }
}
.title-wp {
  position: relative;
  display: inline-block;
  padding-right: 30px;

  .more-info-trigger {
    top: px(4);
    right: 0;
    position: absolute;
		color: $bl-a;

    .material-icons {
      font-size: px(18);
    }
  }
}

.blog-item {
  position: relative;
  height: 100%;
  text-align: left;
  .link {
    display: block;
    background-color: $or-a;
    height: 100%;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
    transition: all 0.2s ease-in-out;
    .img-wp {
      display: block;
      overflow: hidden;
      img {
        transition: all 0.1s ease-in-out;
      }
    }
    .col-bottom {
      background-color: $or-a;
      color: $wh-a;
      text-align: center;
      padding: px(15) px(15) px(18);
    }
    .title {
      font-size: px(14);
      font-family: 'museo500';
      line-height: 1.4em;
    }
    &:hover,
    &:focus {
      box-shadow: 0 0 15px rgba(0,0,0,0);
      .img-wp img {
        transform: scale(1.2);
      }
    }
    @include media-breakpoint-down(md) {
      .title {
        font-size: px(16);
      }
    }
    @include media-breakpoint-down(sm) {
      .title {
        font-size: px(15);
      }
    }
    @include media-breakpoint-down(xs) {
      .img-wp img {
        width: 100%;
      }
      .col-bottom {
        padding: px(20);
      }
    }
  }
}

// Blog :: Category navigation

.nav-category {
  border: 1px solid $gy-a;
  padding: px(20) px(30);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  background-color: $wh-a;
  border-radius: 8px;

  .title-cat {
    font-size: px(13);
    padding-bottom: 6px;
    color: $or-a;
    margin-bottom: px(15);
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid shade($gy-a, 50%);
  }

  .item {
    margin-top: px(5);
  }

  .list .item:first-child {
    margin-top: 0;
  }

  .link {
    position: relative;
    font-weight: 400;
    font-size: px(14);
    color: shade($gy-a, 50%);
    display: block;
    padding-left: px(20);
  }

  .link:before {
    content: "";
    display: block;
    position: absolute;
    top: 10px;
    left: 0;
    width: 8px;
    height: 2px;
    background-color: shade($gy-a, 50%);
    transition: all 0.2s ease-in-out;
  }

  .link.active {
    color: $or-a !important;
    font-weight: 600;
  }

  .link.active:before {
    border-color: $or-a !important;
    background-color: $or-a !important;
  }

  .link:focus,
  .link:hover {
    color: $or-a;
  }

  .link:focus:before,
  .link:hover:before {
    background-color: $or-a;
  }
  @include media-breakpoint-down(sm) {
    border: none;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
// Blog :: Article list
.category-label {
  display: inline-block;
  color: $bl-a;
  background-color: $wh-a;
  font-weight: 600;
  border: 1px solid $wh-a;
  letter-spacing: 0.05em;
  margin-bottom: px(20);
  font-size: px(10);
  padding: 2px 6px 2px;
  border-radius: 3px;
  @include media-breakpoint-down(sm) {
    margin-bottom: px(10);
  }
}

.item-blog {
  position: relative;
  height: 100%;
  display: block;
  padding: 0;
  overflow: hidden;
  background-color: $wh-a;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.15);
  transition: all 0.2s ease-in-out;

  .img-wp {
    position: relative;
    overflow: hidden;
  }

  .img-wp:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.15);
    transition: all 0.2s ease-in-out;
  }

  .img-wp .img-fluid {
    transition: all 0.2s ease-in-out;
  }

  .content {
    padding: 20px;
  }

  .category-blog {
    position: absolute;
    top: 17px;
    z-index: 10;
    right: 20px;
  }

  .title-blog {
    font-size: px(18);
    font-family: 'museo500';
    display: block;
    color: $bl-a;
    margin-bottom: px(8);
    line-height: 1.1em;
  }

  .desc {
    margin-top: px(10);
    font-size: px(14);
    margin-bottom: 0;
    line-height: 1.4em;
  }

  .btn-blog {
    color: $or-a;
    margin-top: 10px;
    font-weight: 600;
    text-align: right;
    display: block;
  }

  &:focus .img-wp .img-fluid,
  &:hover .img-wp .img-fluid {
    transform: scale(1.1);
  }

  &:focus .img-wp:after,
  &:hover .img-wp:after {
    background-color: rgba(0,0,0,0.1);
  }
  @include media-breakpoint-down(sm) {
    .title {
      font-size: px(20);
    }
  }
}

// Pagination
.pagination {
	justify-content: center;
	margin-top: 0;

	.page-link {
		color: $or-a;
		height: 35px;
	}

	.page-link.active {
		background-color: $or-a !important;
		color: $wh-a !important;
		border-top-color: $or-a;
		border-bottom-color: $or-a;
		font-weight: 400;
	}

	.page-item.link-arrow .page-link {
		padding: px(6) px(12);
		background-color: tint($gy-a, 30%);
		color: $or-a;
		transition: all 0.2s ease-in-out;
	}

	.page-item.link-arrow .page-link:focus,
	.page-item.link-arrow .page-link:hover {
		background-color: $or-a;
		color: $wh-a;
	}

	.page-item .page-link:focus,
	.page-item .page-link:hover {
		background-color: tint($gy-a, 90%);
	}
}
