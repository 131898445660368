@font-face {
  font-family: 'museo100';
  src: url("../fonts/museo_100-webfont.woff2") format('woff2'), url("../fonts/museo_100-webfont.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'museo300';
  src: url("../fonts/museo_300-webfont.woff2") format('woff2'), url("../fonts/museo_300-webfont.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'museo500';
  src: url("../fonts/museo_500-webfont.woff2") format('woff2'), url("../fonts/museo_500-webfont.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
// Font family
$font-stack: 'museo300', sans-serif;

html {
  color: $bk-a;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-stack !important;
  transition: opacity 0.1s ease-in-out;
}
// Google font ballback

.wf-active body {
  opacity: 1;
}
// Title :: soft reset

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 1.2em;
  margin-bottom: 0;
}
// Title hierarchy :: Bigger to smaller

.h-like-a {
  font-size: 23px;
  font-family: 'museo500';
}

.h-like-b {
  font-size: 30px;
  font-family: 'museo500';
}

.h-like-c {
  font-size: 15px;
  font-family: 'museo500';
}

.h-like-d {
  font-size: 20px;
  font-family: 'museo500';
}
@include media-breakpoint-down(lg) {
  .h-like-a {}
}
@include media-breakpoint-down(md) {
  .h-like-b {
    font-size: 26px;
  }
}
@include media-breakpoint-down(sm) {
  .h-like-a {
    font-size: 20px;
    font-family: 'museo500';
  }

  .h-like-c {
    font-size: 14px;
  }
}
@include media-breakpoint-down(xs) {
  .h-like-a {}
}
// Paragraph hierarchy :: Bigger to smaller

.p-like-a {
  font-size: 13px;
  font-weight: 300;
}
@include media-breakpoint-down(lg) {
  .p-like-a {}
}
@include media-breakpoint-down(md) {
  .p-like-a {}
}
@include media-breakpoint-down(sm) {
  .p-like-a {}
}
@include media-breakpoint-down(xs) {
  .p-like-a {}
}
